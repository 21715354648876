export default [
  ['#29368f', '#e9697b', '#1b164d', '#f7d996', '#f2e8e4'],
  ['#122438', '#dd672e', '#87c7ca', '#ebebeb'],
  ['#eab700', '#e64818', '#2c6393', '#eecfca', '#e7e6e4'],
  ['#20342a', '#f74713', '#686d2c', '#e9b4a6', '#e5ded8'],
  ['#1d3b1a', '#eb4b11', '#e5bc00', '#f29881', '#eae2d0'],
  ['#ec643b', '#56b7ab', '#f8cb57', '#1f1e43', '#f7f2df'],
  ['#ff3931', '#007861', '#311f27', '#bab9a4', '#dddddd'],
  ['#253852', '#51222f', '#b53435', '#ecbb51', '#eeccc2'],
  ['#ff3250', '#ffb33a', '#008c36', '#0085c6', '#4c4c4c', '#faf2e5'],
  ['#c54514', '#dca215', '#23507f', '#e8e7d4'],
  ['#f40104', '#f6c0b3', '#99673a', '#f0f1f4', '#191e36'],
  ['#bf4a2b', '#cd902a', '#4e4973', '#f5d4bc', '#1e1a43'],
  ['#f1594a', '#f5b50e', '#14a160', '#2969de', '#885fa4', '#e2e6e8'],
  ['#ea7251', '#ebf7f0', '#02aca5', '#9ae2d3'],
  ['#e2d574', '#f1f4f7', '#69c5ab', '#f79eac'],
  ['#f05e3b', '#ebdec4', '#ffdb00', '#161616'],
  ['#f2d002', '#f7f5e1', '#ec643b'],
  ['#a49f4f', '#d4501e', '#f7c558', '#ebbaa6', '#f7f4ef'],
  ['#ec2f28', '#f8cd28', '#1e95bb', '#fbaab3', '#fcefdf'],
  ['#ff5500', '#f4c145', '#144714', '#2f04fc', '#e276af', '#fff3dd'],
  ['#ed555d', '#fffcc9', '#41b797', '#eda126', '#7b5770', '#2d1922'],
  ['#e8165b', '#401e38', '#66c3b4', '#ee7724', '#584098', '#ffffff'],
  ['#ff6936', '#fddc3f', '#0075ca', '#00bb70', '#020202'],
  ['#BCAA8C', '#D8CDBE', '#484A42', '#746B58', '#9A8C73', '#fff'],
  ['#df456c', '#ea6a82', '#270b32', '#471e43', '#ef9198'],
  ['#f6c103', '#f6f6f6', '#d1cdc7', '#e7e6e5', '#f5f5f5'],
  ['#be1c24', '#d1a082', '#037b68', '#d8b1a5', '#1c2738', '#c95a3f', '#f5b28a'],
  ['#20357e', '#f44242', '#ffffff', '#cfc398'],
  ['#e16503', '#dc9a0f', '#dfe2b4', '#66a7a6', '#3c1c03'],
  ['#df302f', '#e5a320', '#0466b3', '#0f7963', '#dedccd'],
  ['#e24724', '#c7c7c7', '#1f3e7c', '#d29294', '#010203', '#f2f2f2'],
  ['#4f423a', '#f6a74b', '#589286', '#f8e9e2', '#2c2825', '#fff'],
  ['#1767D2', '#FFFFFF', '#F9AB00', '#212121', '#fff'],
  ['#ec6c26', '#613a53', '#e8ac52', '#639aa0', '#d5cda1'],
  ['#d3693e', '#803528', '#f1b156', '#90a798', '#f0e0a4'],
  ['#f46e26', '#68485f', '#3d273a', '#535d55', '#dcd4a6'],
  ['#ea720e', '#ca5130', '#e9c25a', '#52534f', '#f9ecd3'],
  ['#ce565e', '#8e1752', '#f8a100', '#3ac1a6', '#efdea2'],
  ['#f5736a', '#925951', '#feba4c', '#9d9b9d', '#eedfa2'],
  ['#69766f', '#9ed6cb', '#f7e5cc', '#9d8f7f', '#936454', '#bf5c32', '#efad57'],
  ['#878a87', '#cbdbc8', '#e8e0d4', '#b29e91', '#9f736c', '#b76254', '#dfa372'],
  ['#705f84', '#687d99', '#6c843e', '#fc9a1a', '#dc383a', '#aa3a33', '#9c4257'],
  ['#817c77', '#396c68', '#89e3b7', '#f59647', '#d63644', '#893f49', '#4d3240'],
  ['#fd3741', '#fe4f11', '#ff6800', '#ffa61a', '#ffc219', '#ffd114', '#fcd82e', '#f4d730', '#ced562', '#8ac38f', '#79b7a0', '#72b5b1', '#5b9bae', '#6ba1b7', '#49619d', '#604791', '#721e7f', '#9b2b77', '#ab2562', '#ca2847'],
  ['#40708c', '#8e998c', '#5d3f37', '#ed6954', '#f2e9e2'],
  ['#5f9e93', '#3d3638', '#733632', '#b66239', '#b0a1a4', '#e3dad2'],
  ['#87c3ca', '#7b7377', '#b2475d', '#7d3e3e', '#eb7f64', '#d9c67a', '#f3f2f2'],
  ['#d53939', '#b6754d', '#a88d5f', '#524643', '#3c5a53', '#7d8c7c', '#dad6cd'],
  ['#e3dd34', '#78496b', '#f0527f', '#a7e0e2', '#e0eff0'],
  ['#ffce49', '#ede8dc', '#ff5736', '#ff99b4', '#f7f4ed'],
  ['#5c5f46', '#ff7044', '#ffce39', '#66aeaa', '#e9ecde'],
  ['#553c60', '#ffb0a0', '#ff6749', '#fbe090', '#f5e9de'],
  ['#bbd444', '#fcd744', '#fa7b53', '#423c6f', '#faf4e4'],
  ['#0d4a4e', '#ff947b', '#ead3a2', '#5284ab', '#f6f4ed'],
  ['#363d4a', '#7b8a56', '#ff9369', '#f4c172', '#f0efe2'],
  ['#004996', '#567bae', '#ff4c48', '#ffbcb3', '#fff8e7'],
  ['#004996', '#567bae', '#ffc000', '#ffdca4', '#fff8e7'],
  ['#004996', '#567bae', '#60bf3c', '#d2deb1', '#fff8e7'],
  ['#4d3d9a', '#f76975', '#ffffff', '#eff0dd', '#58bdbc'],
  ['#abdfdf', '#fde500', '#58bdbc', '#eff0dd', '#f76975'],
  ['#fde500', '#2f2043', '#f76975', '#eff0dd', '#fbbeca'],
  ['#395e54', '#e77b4d', '#050006', '#e55486', '#efe0bc'],
  ['#809498', '#d3990e', '#000000', '#ecddc5', '#863f52'],
  ['#ecddc5', '#79b27b', '#000000', '#ac6548', '#d5c08e'],
  ['#f3cb4d', '#f2f5e3', '#20191b', '#67875c', '#433d5f'],
  ['#c37c2b', '#f6ecce', '#000000', '#386a7a', '#e3cd98'],
  ['#596f7e', '#eae6c7', '#463c21', '#f4cb4c', '#e67300'],
  ['#c75669', '#000000', '#11706a', '#ecddc5'],
  ['#6b5c6e', '#4a2839', '#d9574a', '#ffc34b'],
  ['#e9dcad', '#143331', '#ffc000', '#a74c02'],
  ['#c47c2b', '#5f5726', '#000000', '#7e8a84', '#ecddc5'],
  ['#c15e1f', '#e4a13a', '#000000', '#4d545a', '#dfc79b'],
  ['#4bae8c', '#d0c1a0', '#2d3538', '#d06440'],
  ['#f6d700', '#f2d692', '#000000', '#5d3552', '#ff7426'],
  ['#c65f75', '#d3990e', '#000000', '#597e7a', '#f6eccb'],
  ['#dd614a', '#f5cedb', '#1a1e4f', '#fbb900'],
  ['#f04924', '#fcce09', '#408ac9', '#ffffff'],
  ['#f8c3df', '#f2e420', '#28b3d0', '#648731', '#ef6a7d', '#f2f0e1'],
  ['#ca3122', '#e5af16', '#4a93a2', '#0e7e39', '#e2b9bd', '#e3ded8'],
  ['#f0afb7', '#f6bc12', '#1477bb', '#41bb9b', '#e3ded8'],
  ['#ee8067', '#f3df76', '#00a9c0', '#f7ab76', '#f5efcb'],
  ['#f0865c', '#f2b07b', '#6bc4d2', '#1a3643', '#f0f0e8'],
  ['#fe765a', '#ffb468', '#4b588f', '#faf1e0', '#fae5c8'],
  ['#e76c4a', '#f0d967', '#7f8cb6', '#1daeb1', '#ef9640', '#e2ded2'],
  ['#d24c23', '#7ba6bc', '#f0c667', '#ede2b3', '#672b35', '#142a36', '#108266'],
  ['#e8dccc', '#e94641', '#eeaeae', '#6c96be'],
  ['#e3937b', '#d93f1d', '#090d15', '#e6cca7', '#558947'],
  ['#d03718', '#292b36', '#33762f', '#ead7c9', '#ce7028', '#689d8d', '#deb330'],
  ['#de3f1a', '#de9232', '#007158', '#e6cdaf', '#869679', '#7aa5a6'],
  ['#a87c2a', '#bdc9b1', '#f14616', '#ecbfaf', '#017724', '#0e2733', '#2b9ae9', '#dfd4c1'],
  ['#d57846', '#dfe0cc', '#de442f', '#e7d3c5', '#5ec227', '#302f35', '#63bdb3', '#dfd4c1'],
  ['#c91619', '#fdecd2', '#f4a000', '#4c2653', '#89c2cd'],
  ['#4aad8b', '#e15147', '#f3b551', '#cec8b8', '#d1af84', '#544e47', '#cfc7b9'],
  ['#75974a', '#c83e3c', '#f39140', '#e4ded2', '#f8c5a4', '#434f55', '#cfc7b9'],
  ['#687f72', '#cc7d6c', '#dec36f', '#dec7af', '#ad8470', '#424637', '#cfc7b9'],
  ['#172a89', '#f7f7f3', '#f3abb0'],
  ['#302956', '#f3c507', '#eee3d3'],
  ['#000000', '#a7a7a7', '#0a5e78'],
  ['#50978e', '#f7f0df'],
  ['#ee5d65', '#f0e5cb'],
  ['#271f47', '#e7ceb5', '#cc2b1c'],
  ['#6a98a5', '#d24c18', '#efebda'],
  ['#5d9d88', '#ebb43b', '#efebda'],
  ['#052e57', '#de8d80', '#efebda'],
  ['#e5dfcf', '#151513', '#e9b500'],
  ['#ec5526', '#f4ac12', '#9ebbc1', '#f7f4e2', '#e7e8d4'],
  ['#eb5627', '#eebb20', '#4e9eb8', '#f7f5d0', '#77c1c0'],
  ['#e95145', '#f8b917', '#b8bdc1', '#ffb2a2', '#6b7752'],
  ['#e95145', '#f6bf7a', '#589da1', '#f5d9bc', '#f5ede1'],
  ['#ff6555', '#ffb58f', '#d8eecf', '#8c4b47', '#bf7f93', '#ffda82'],
  ['#f75952', '#ffce84', '#74b7b2', '#f6f6f6', '#b17d71', '#f6ecd4'],
  ['#ff5937', '#f6f6f4', '#4169ff'],
  ['#ff5937', '#f6f6f4'],
  ['#f6f6f4', '#ff5937'],
  ['#4169ff', '#f6f6f4'],
  ['#f6f6f4', '#4169ff'],
  ['#fc3032', '#fed530', '#33c3fb', '#ff7bac', '#fda929', '#ffffff'],
  ['#e72e81', '#f0bf36', '#3056a2', '#ffffff'],
  ['#f13274', '#eed03e', '#405e7f', '#19a198', '#ffffff'],
  ['#ff7bac', '#ff921e', '#3ea8f5', '#7ac943', '#ffffff'],
  ['#e51c39', '#f1b844', '#36c4b7', '#666666', '#ffffff'],
  ['#ff4242', '#fec101', '#1841fe', '#fcbdcc', '#82e9b5', '#fff'],
  ['#ff4242', '#ffd480', '#1e365d', '#edb14c', '#418dcd', '#fff'],
  ['#f73f4a', '#d3e5eb', '#002c3e', '#1aa1b1', '#ec6675', '#fff'],
  ['#e31f4f', '#f0ac3f', '#18acab', '#26265a', '#ea7d81', '#dcd9d0'],
  ['#db4549', '#d1e1e1', '#3e6a90', '#2e3853', '#a3c9d3', '#fff'],
  ['#e5475c', '#95b394', '#28343b', '#f7c6a3', '#eb8078', '#fff'],
  ['#d75c49', '#f0efea', '#509da4', '#fff'],
  ['#f6625a', '#92b29f', '#272c3f', '#fff'],
  ['#c92a28', '#e69301', '#1f8793', '#13652b', '#e7d8b0', '#48233b', '#e3b3ac', '#f0f0f2'],
  ['#475b62', '#7a999c', '#2a1f1d', '#fbaf3c', '#df4a33', '#f0e0c6', '#af592c'],
  ['#13477b', '#2f1b10', '#d18529', '#d72a25', '#e42184', '#138898', '#9d2787', '#7f311b', '#dfc792'],
  ['#e85b30', '#ef9e28', '#c6ac71', '#e0c191', '#3f6279', '#ee854e', '#180305', '#ede4cb'],
  ['#c03a53', '#edd09e', '#aab5af', '#023629', '#eba735', '#8e9380', '#6c4127', '#e6e2d6'],
  ['#df9f00', '#1f6f50', '#8e6d7f', '#da0607', '#a4a5a7', '#d3d1c3', '#42064f', '#25393a', '#f0f5f6'],
  ['#99cb9f', '#cfb610', '#d00701', '#dba78d', '#2e2c1d', '#bfbea2', '#d2cfaf', '#e3e2c5'],
  ['#313a42', '#9aad2e', '#f0ae3c', '#df4822', '#8eac9b', '#cc3d3f', '#ec8b1c', '#1b9268', '#e5e2cf'],
  ['#f14d42', '#f4fdec', '#4fbe5d', '#265487', '#f6e916', '#f9a087', '#2e99d6'],
  ['#adb100', '#e5f4e9', '#f4650f', '#4d6838', '#cb9e00', '#689c7d', '#e2a1a8', '#151c2e', '#cecaa9'],
  ['#f4b232', '#f2dbbd', '#01799c', '#e93e48', '#0b1952', '#006748', '#ed817d', '#f0dbbc'],
  ['#5399b1', '#f4e9d5', '#de4037', '#ed942f', '#4e9e48', '#7a6e62', '#f0c328'],
  ['#FBF5E9', '#FF514E', '#FDBC2E', '#4561CC', '#2A303E', '#6CC283', '#A71172', '#238DA5', '#9BD7CB', '#231E58', '#4E0942'],
  ['#FBF5E9', '#FF514E', '#FDBC2E', '#4561CC', '#2A303E', '#6CC283', '#238DA5', '#9BD7CB'],
  ['#ff7a5a', '#765aa6', '#fee7bc', '#515e8c', '#ffc64a', '#b460a6', '#ffffff', '#4781c1', '#abe9e8'],
  ['#ae5d9d', '#f1e8bc', '#ef8fa3', '#f7c047', '#58c9ed', '#f77150', '#00ae83'],
  ['#f77656', '#f7f7f7', '#efc545', '#dfe0e2', '#3c70bd', '#66bee4', '#f6e0b8'],
  ['#000000', '#d55a3a', '#2a5c8a', '#7e7d14', '#dbdac9', '#f4e9d5'],
  ['#dbdac9', '#d55a3a', '#2a5c8a', '#b47b8c', '#7e7d14', '#000000'],
  ['#dbdac9', '#d55a3a', '#2a5c8a', '#000000'],
  ['#dbdac9', '#d55a3a', '#7e7d14', '#000000'],
  ['#ea510e', '#ffd203', '#0255a3', '#039177', '#111111', '#fff'],
  ['#ea663f', '#f9cc27', '#84afd7', '#7ca994', '#f1bbc9', '#242424', '#f5f6f1'],
  ['#ea5b19', '#f8c9b9', '#137661', '#2a2a2a', '#f5f4f0'],
  ['#8bc9c3', '#ffae43', '#ea432c', '#228345', '#d1d7d3', '#524e9c', '#9dc35e', '#f0a1a1', '#000000'],
  ['#8bc9c3', '#ffae43', '#ea432c', '#524e9c', '#000000'],
  ['#8bc9c3', '#ffae43', '#ea432c', '#524e9c', '#f0a1a1', '#228345', '#000000'],
  ['#ffae43', '#ea432c', '#524e9c', '#f0a1a1', '#000000'],
  ['#111122', '#ff2522', '#eeeef5']
]
