import { ethers } from 'ethers'
import { Module } from 'vuex'
import ThingToken from '@/contracts/ThingToken.json'
import router from '@/router'
import store from '@/store'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { ethereum: any }
}

export interface State {
  totalSupply?: number
  mintedSupply?: number
  feeInWei?: number
  provider: ethers.providers.Web3Provider | null
  externalProvider: ethers.providers.InfuraProvider
  address: string | null
  ensName: string | null
  contractAddress: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const blockchain: Module<State, any> = {
  namespaced: true,
  state: () => ({
    totalSupply: undefined,
    mintedSupply: undefined,
    feeInWei: undefined,
    provider: window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, 'any') : null,
    externalProvider: new ethers.providers.InfuraProvider('rinkeby', process.env.INFURA_PROJECT_ID),
    address: null,
    ensName: null,
    contractAddress: ThingToken.address
  }),
  mutations: {
    setAddress: (state, newAddress) => {
      state.address = newAddress
    },
    setEnsName: (state, newEnsName) => {
      state.ensName = newEnsName
    },
    setTokenData: (state, { totalSupply, mintedSupply, feeInWei }) => {
      state.totalSupply = totalSupply
      state.mintedSupply = mintedSupply
      state.feeInWei = feeInWei
    }
  },
  actions: {
    async setupMetamask ({ state, dispatch }) {
      if (state.provider === null) return

      const accounts = await state.provider.listAccounts()
      if (accounts.length) dispatch('connectWallet')

      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length) dispatch('connectWallet')
      })

      window.ethereum.on('chainChanged', () => {
        window.location.reload()
      })
    },
    async connectWallet ({ state, commit, dispatch }) {
      if (state.provider === null) {
        dispatch('error', 'NO_METAMASK', { root: true })
        return
      }

      await state.provider.send('eth_requestAccounts', [])

      const network = await state.provider.getNetwork()

      if (network.name !== 'rinkeby') {
        dispatch('error', 'WRONG_CHAIN', { root: true })
        return
      }

      const signer = state.provider.getSigner()
      const address = await signer.getAddress()
      const ensName = await state.provider.lookupAddress(address)

      commit('setAddress', address)
      commit('setEnsName', ensName)
    },
    async updateTokenData ({ getters, commit }) {
      const contract = await getters.contract
      const maxSupply = await contract.cap()
      const mintedSupply = await contract.totalSupply()
      // const fee = await contract.fee()

      commit('setTokenData', {
        totalSupply: parseInt(maxSupply._hex),
        mintedSupply: parseInt(mintedSupply._hex),
        feeInWei: 0 // parseInt(fee._hex)
      })
    },
    async mint ({ dispatch, getters }) {
      const contract = await getters.signer

      dispatch('ui/beginMint', null, { root: true })
      if (!contract) {
        dispatch('ui/endMint', null, { root: true })
        return
      }

      const maxSupply = await contract.cap()
      const mintedSupply = await contract.totalSupply()

      if (parseInt(mintedSupply._hex) >= parseInt(maxSupply._hex)) {
        dispatch('error', 'SOLD_OUT', { root: true })
        dispatch('ui/endMint', null, { root: true })
        return
      }

      try {
        const transaction = await contract.createRandomThing({
          // value: await contract.fee()
        })

        const newToken = await transaction.wait()
        const event = newToken.events.find((e: { event: string }) => e.event === 'Transfer')
        router.push({ name: 'Thing', params: { id: parseInt(event.args.tokenId).toString() } })

        dispatch('ui/endMint', null, { root: true })
      } catch (error) {
        dispatch('ui/endMint', null, { root: true })
        return null
      }
    },
    async updateMetadata ({ getters }, { tokenId, name, description }) {
      const contract = await getters.signer

      if (!contract) return null

      try {
        const transaction = await contract.updateMetadata(tokenId, name, description)
        const receipt = await transaction.wait()
        const event = receipt.events.find((e: { event: string }) => e.event === 'UpdateMetadata')
        if (event) return { name: event.args.name, description: event.args.description }
        return { name: 'name', description: 'description' }
      } catch (error) {
        return null
      }
    }
  },
  getters: {
    async contract (state) {
      let readProvider = state.externalProvider as ethers.providers.Provider

      if (state.provider !== null) {
        const network = await state.provider.getNetwork()
        if (network.name === 'rinkeby') {
          readProvider = state.provider
        }
      }

      return new ethers.Contract(ThingToken.address, ThingToken.abi, readProvider)
    },
    async signer (state) {
      if (state.provider === null) {
        store.dispatch('error', 'NO_METAMASK', { root: true })
        return
      }

      const network = await state.provider.getNetwork()
      if (network.name !== 'rinkeby') {
        store.dispatch('error', 'WRONG_CHAIN', { root: true })
        return
      }

      const signer = state.provider.getSigner()

      if (!signer) {
        store.dispatch('error', 'DISCONNECTED', { root: true })
        return
      }

      return new ethers.Contract(ThingToken.address, ThingToken.abi, signer)
    }
  }
}

export default blockchain
