




import { Component, Prop, Vue } from 'vue-property-decorator'
import MersenneTwister from 'mersennetwister'
import SimplexNoise from 'simplex-noise'
import palettes from './palettes'
import ApparatusBuilder from './apparatus'
import parseThing from '@/ts/parseThing'

@Component({ })
export default class ThingImage extends Vue {
  @Prop(Number) dna!: number
  @Prop(Number) size!: number
  @Prop(String) symmetry!: string
  @Prop(Number) latitude!: number
  @Prop(Number) longitude!: number
  @Prop({ default: 300 }) width!: number
  @Prop({ default: 300 }) height!: number

  imageSrc = ''

  mounted (): void {
    this.draw()
  }

  draw (): void {
    const randThing = parseThing(Math.round(Math.random() * 1e19))

    this.imageSrc = this.art({
      dna: typeof this.dna !== 'undefined' ? this.dna : randThing.dna,
      size: typeof this.size !== 'undefined' ? this.size : randThing.size,
      symmetry: typeof this.symmetry !== 'undefined' ? this.symmetry : randThing.symmetry,
      latitude: typeof this.latitude !== 'undefined' ? this.latitude : randThing.latitude,
      longitude: typeof this.longitude !== 'undefined' ? this.longitude : randThing.longitude,
      width: this.width,
      height: this.height
    })
  }

  art ({ dna, size, symmetry, latitude, longitude, width, height }: {
    dna: number,
    size: number,
    symmetry: string,
    latitude: number,
    longitude: number,
    width: number,
    height: number
  }): string {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const rng = new MersenneTwister(dna)
    function random () { return rng.random() }
    const simplex = new SimplexNoise(random())

    // * Main Mods
    const thingSize = size
    const cellSize = Math.round(Math.min(width, height) / thingSize / 2 / 1.618)
    const iterations = 8 + Math.round(random() ** 3 * 3) * Math.sign(random() - 0.5)
    const blurry = Math.round((1 - random() ** 4) * 100 + 27)
    // *

    const colors = [...palettes[Math.floor((longitude + 180) / 360 * palettes.length)]]

    // shuffle colors
    for (let i = colors.length - 1; i > 0; i--) {
      const j = Math.floor((latitude + 90) / 180 * (i + 1));
      [colors[i], colors[j]] = [colors[j], colors[i]]
    }

    const background = colors.pop() as string
    this.$emit('background', background)

    const apparatus = new ApparatusBuilder(thingSize, thingSize, {
      initiateChance: random(),
      extensionChance: random() * 0.70,
      verticalChance: random(),
      simple: false,
      horizontalSymmetry: symmetry !== 'none',
      verticalSymmetry: symmetry === 'xy',
      roundness: random() ** 5 * 0.8,
      solidness: random() / 2 + 0.25,
      colors,
      colorMode: ['group', 'main', 'random'][Math.floor(random() ** 3 * 3)],
      groupSize: 0.85,
      simplex
    }, random)

    let time = 0

    if (random() > 0.5) {
      ctx.translate(width / 2, height / 2)
      ctx.rotate(Math.PI)
      ctx.translate(-width / 2, -height / 2)
    }

    if (random() > 0.5) {
      ctx.translate(width / 2, height / 2)
      ctx.scale(-1, 1)
      ctx.translate(-width / 2, -height / 2)
    }

    ctx.fillStyle = background
    ctx.fillRect(0, 0, width, height)

    const animate = () => {
      rng.seed(dna)

      const rects = apparatus.generate(null, null, 0, 0)
      apparatus.simplex = {
        noise3D: (a: number, b: number, c: number) => simplex.noise4D(a, b, c, time)
      }

      function rectAtPos (x: number, y: number) {
        return rects.find(rect => {
          const inX = x >= (rect.x1 - 1) && x <= (rect.x1 - 1) + rect.w
          const inY = y >= (rect.y1 - 1) && y <= (rect.y1 - 1) + rect.h
          return inX && inY
        })
      }

      for (let x = 0; x < thingSize * 2; x++) {
        for (let y = 0; y < thingSize * 2; y++) {
          ctx.beginPath()
          ctx.rect(
            x * cellSize + Math.round(width / 2 - cellSize * thingSize),
            y * cellSize + Math.round(height / 2 - cellSize * thingSize),
            cellSize,
            cellSize
          )

          if (rectAtPos(x, y)) {
            ctx.fillStyle = rectAtPos(x, y).col + (time === 0 ? 'ff' : (255 - blurry).toString(16).padStart(2, '0'))
            ctx.fill()
          }
        }
      }

      time += 0.01
    }

    for (let i = 0; i < iterations; i++) {
      animate()
    }

    return canvas.toDataURL('image/png')
  }
}
