









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ErrorModal extends Vue {
  @Prop({ type: String }) message!: string
}
