import { Module } from 'vuex'

export interface State {
  displayGrid: boolean
  menuOpen: boolean
  isMinting: boolean
  headerTextColor: { left: string, right: string }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uiModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    displayGrid: false,
    menuOpen: false,
    isMinting: false,
    headerTextColor: { left: '#000', right: '#000' }
  }),
  mutations: {
    toggleGrid (state: State, force?: boolean): void {
      state.displayGrid = force === undefined ? !state.displayGrid : force
    },
    toggleMenu (state: State, force?: boolean): void {
      state.menuOpen = force === undefined ? !state.menuOpen : force
    },
    setIsMinting (state: State, val: boolean): void {
      state.isMinting = val
    },
    setHeaderTextColor (state: State, bgColors: { left: string, right: string }): void {
      const colorIsDark = (hex: string) => {
        const hasFullSpec = hex.length === 7
        const m = hex.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)

        if (m) {
          const r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16) / 255
          const g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16) / 255
          const b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16) / 255

          const luminance = (Math.max(r, g, b) + Math.min(r, g, b)) / 2

          return luminance < 0.25
        } else {
          return false
        }
      }

      const readableColors = {
        left: colorIsDark(bgColors.left) ? '#fff' : '#000',
        right: colorIsDark(bgColors.right) ? '#fff' : '#000'
      }
      state.headerTextColor = readableColors
    }
  },
  actions: {
    beginMint ({ commit }): void {
      commit('setIsMinting', true)
      commit('setHeaderTextColor', { left: '#000', right: '#000' })
    },
    endMint ({ commit }): void {
      commit('setIsMinting', false)
      commit('setHeaderTextColor', { left: '#fff', right: '#fff' })
    }
  }
}

export default uiModule
