import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'THINGS | Let\'s create something' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { title: 'THINGS | What are these?' }
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue'),
    meta: { title: 'THINGS | Gallery of everything' }
  },
  {
    path: '/thing/nonexistant',
    name: 'NonexistantThing',
    component: () => import(/* webpackChunkName: "nonexistant-thing" */ '../views/NonexistantThing.vue'),
    meta: { title: 'THINGS | Nonexistant Thing' }
  },
  {
    path: '/thing/:id',
    name: 'Thing',
    component: () => import(/* webpackChunkName: "thing" */ '../views/Thing.vue'),
    meta: { title: 'THINGS | Let\'s create something' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title || 'THINGS | Let\'s create something'
    store.commit('ui/toggleMenu', false)
  })
})

export default router
