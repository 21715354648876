import Vue from 'vue'
import Vuex from 'vuex'
import ui from './store-ui'
import blockchain from './store-blockchain'
import errors, { errorsType } from './errors'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errors: [] as string[]
  },
  mutations: {
    addError (state, message: string) {
      state.errors.push(message)
    },
    removeError (state, message: string) {
      const index = state.errors.indexOf(message)
      if (index >= 0) state.errors.splice(index, 1)
    }
  },
  actions: {
    error ({ commit }, errorName: errorsType) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.log(errors[errorName])
      commit('addError', errors[errorName])
    }
  },
  modules: { ui, blockchain }
})
