











import { Component, Prop, Vue } from 'vue-property-decorator'
import ThingImage from '@/components/ThingImage/ThingImage.vue'

@Component({ components: { ThingImage } })
export default class ThingsBackgroundEffect extends Vue {
  @Prop({ default: 15 }) population!: number
  @Prop({ default: 1.5 }) size!: number

  images: Array<{ x: number, y: number, z: number, rotation: number }> = []
  terrainSize!: { h: number, w: number }

  mounted (): void {
    this.onResize()
    window.addEventListener('resize', this.onResize)

    for (let i = 0; i < this.population; i++) {
      this.images.push({
        x: (Math.random() - 0.5) * this.terrainSize.w,
        y: (Math.random() - 0.5) * this.terrainSize.h,
        z: Math.random() + 1,
        rotation: (Math.random() - 0.5) * 50
      })
    }

    this.animate()
  }

  animate (): void {
    this.images.forEach(image => {
      image.y -= 0.001 / image.z
      if (image.y < this.terrainSize.h / -2) {
        image.y = this.terrainSize.h / 2
        image.x = (Math.random() - 0.5) * this.terrainSize.w
        image.z = Math.random() + 1
        image.rotation = (Math.random() - 0.5) * 50
      }
    })
    requestAnimationFrame(this.animate)
  }

  onResize (): void {
    const ratio = window.innerHeight / window.innerWidth
    this.terrainSize = ratio > 1 ? { w: this.size * ratio, h: this.size } : { w: this.size, h: this.size / ratio }
  }

  beforeUnmount (): void {
    window.removeEventListener('resize', this.onResize)
  }
}
