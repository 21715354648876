
export default function parseThing (seed: number): { dna: number, size: number, symmetry: string, latitude: number, longitude: number } {
  return {
    dna: seed % 1e16,
    size: 12 + Math.floor(((seed % 1e5) * 11e-5) ** 2 / 11),
    symmetry: ((seed % 1e5) * 3e-5) ** 10 > 39366 ? 'xy' : ((seed % 1e5) * 3e-5) ** 10 > 19683 ? 'x' : 'none',
    latitude: Math.acos(2 * seed / 1e19 - 1) / Math.PI * 180 - 90,
    longitude: (seed % 36000) / 100 - 180
  }
}
