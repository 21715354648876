
export type errorsType = 'NO_METAMASK' | 'WRONG_CHAIN' | 'SOLD_OUT' | 'DISCONNECTED'

const errors = {
  NO_METAMASK: 'Please install Metamask.',
  WRONG_CHAIN: 'Please connect with Rinkeby testnet.',
  SOLD_OUT: 'All tokens have already been minted...',
  DISCONNECTED: 'Please connect to Metamask to mint.'
}

export default errors
