








import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Minting extends Vue {
  dots = '.'

  mounted (): void {
    setInterval(() => {
      this.dots = ['.', '..', '...', '....'][this.dots.length % 4]
    }, 500)
  }
}
