




























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  get walletAddress (): string | null {
    let address = this.$store.state.blockchain.address
    if (address) {
      const ens = this.$store.state.blockchain.ensName
      address = ens || address.slice(0, 5) + '…' + address.slice(-3)
    }
    return address
  }
}
