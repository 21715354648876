



























import { Component, Vue } from 'vue-property-decorator'
import ThingsBackgroundEffect from '@/components/ThingsBackgroundEffect.vue'
import { utils } from 'ethers'

@Component({ components: { ThingsBackgroundEffect } })
export default class Home extends Vue {
  formatWei (wei: number): string {
    return utils.formatEther(wei)
  }
}
