















import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Header from '@/components/Header.vue'
import Minting from '@/components/Minting.vue'
import GridHelper from '@/components/GridHelper.vue'
import ErrorModal from '@/components/ErrorModal.vue'

@Component({ components: { ErrorModal, Header, Minting, GridHelper } })
export default class App extends Vue {
  devMode = process.env.NODE_ENV === 'development'

  mounted (): void {
    dayjs.extend(relativeTime)
    this.$store.dispatch('blockchain/setupMetamask')
    this.$store.dispatch('blockchain/updateTokenData')
    document.addEventListener('dblclick', () => this.$store.commit('ui/toggleGrid'))
  }
}
